var refreshShoppingCart = function (actionBeforeRefresh) {
}
var closeOpenProduct = function (parentWrapper) {
}

$(document).ready(function () {

    var ajaxPath = '/includes/shoppingCart/ajax/ajax.php';
    var ajaxPathSetConsumingLocation = '/action/shop/set-consuming-location';
    var WIDTH_SM = 768;
    var modalParentElementInDOM = $('div#main-container');
    var modalTitle = '';
    var modalMessage = '';
    var modalButtonText = 'Lukk';
    var modalAlertType = '';


    //Add a "read more" link after product description if product layout 2 or 3 is active
    if ($('div.product_layout_2').length || $('div.product_layout_4').length) {
        addReadMoreLinkOnProductDescriptions();
    }

    closeOpenProduct = function (parentWrapper) {
        var mobileView = ($(window).width() <= 768 && SUB_DOMAIN == 'isushi');
        if (mobileView) {
            hidePurchaseOptionsMobile(parentWrapper);
        } else {
            hidePurchaseOptions(parentWrapper);
        }
    };

    // Display option to purchase product
    $(document).on('click', 'span.glyphicon-plus-sign', function (event) {
        //Fix gap/height issues on mobile when picture disappears:
        var productArticleDivParent = $(this).parents('div.product_article');
        productArticleDivParent.css("min-height", productArticleDivParent.height());

        // Get parent wrapper (wraps both image and info for this product)
        var productArticle = $(this).parents('article');

        // Show description
        $('p.description span.hidden-normal', productArticle).removeClass('hidden');

        // Hide image and display purchase options
        $('img', productArticle).css('height', '0px');

        //If the class "product_article_hide_picture" is added, the config "hide default pictures" is active
        // -> Remove position: absolute from the info_wrapper to make space for other info about the product
        if ($('div#products_wrapper div.product_article_hide_picture'). length > 0) {
            $(this).parents('div.product_info_wrapper').css('position', '');
        }

        // Hide direct order button
        $(productArticle).find('button.add_product_simple').hide();
        //$('button.add_product_simple').hide();

        // Switch plus sign with minus sign
        switchFromPlusToMinus($(this));

        //Add style to article when purchase-options opens
        productArticle.removeClass('closed-purchase-options');
        productArticle.addClass('open-purchase-options');

        //If product_layout_1 is activated
        if ($('div.product_layout_1').length) {
            // Hide button "Vis mer..."
            $(productArticle).find('button.description-read-more').hide();
        }

        //If product_layout_2 is activated -> hide order button
        if ($('div.product_layout_2').length) {
            $(productArticle).find('div.order-button-form-group').hide();
            $(productArticle).find('div.product_image_wrapper').hide();
        }

        //If product_layout_3 is activated -> hide image wrapper
        if ($('div.product_layout_3').length) {
            $(productArticle).find('div.product_image_wrapper').hide();
        }

        // Display purchase option
        $('div.purchase_product_wrapper', productArticle).show();
    });

    // Display option to purchase product in mobile view
    $(document).on('click', 'div#products_wrapper div.product_article_mobile:not(.open)', function () {
        $(this).addClass('open');

        // Get parent wrapper (wraps both image and info for this product)
        var productArticle = $('article', this);

        // Show description
        $('p.description span.hidden-normal', productArticle).removeClass('hidden');

        // Display image and display purchase options
        $('img', productArticle).css('display', 'block');

        // Switch plus sign with minus sign
        switchFromArrowToMinus($('span.glyphicon-chevron-right', this));

        // Display description
        $('p.description', productArticle).show();

        // Display purchase option
        $('div.purchase_product_wrapper', productArticle).show();
    });

    // Hide option to purchase product and display image
    $(document).on('click', 'div#products_wrapper span.glyphicon-minus-sign', function () {

        // Get parent wrapper (wraps both image and info for this product)
        var productArticle = $(this).parents('article');

        //If the class "product_article_hide_picture" is added, the config "hide default pictures" is active
        // -> Add position: absolute to the info_wrapper to place it back again after removing it
        if ($('div#products_wrapper div.product_article_hide_picture'). length > 0) {
            $(this).parents('div.product_info_wrapper').css('position', 'absolute');
        }

        hidePurchaseOptions(productArticle);
    });

    // Hide option to purchase product and display image
    $(document).on('click', 'div.product_article_mobile span.glyphicon-minus-sign', function () {
        // Get parent wrapper (wraps both image and info for this product)
        var productArticle = $(this).parents('article');
        hidePurchaseOptionsMobile(productArticle);
    });

    // Add product to shopping cart
    $(document).on('click', 'button.add_product', function () {
        var parentWrapper = $(this).parents('article');
        var specialProduct = $(this).attr('special_product');
        var extraProductAutoPopup = $(this).hasClass('extra-products-popup');
        let productInfoWrapper = $(this).prev('.product_info_wrapper');
        if ((specialProduct !== undefined && specialProduct !== false && specialProduct != '') || extraProductAutoPopup) {
            $('button.extra-products-btn:first', parentWrapper).click();
            return false;
        } else {
            // Find the input field element
            var input = $('input', parentWrapper);
            var amount = $.trim(input.val()) * 1;

            // Validate amount
            if (!isInteger(amount)) {
                // Add red border on the amount input
                var parentFormGroup = input.parents('div.form-group');
                parentFormGroup.addClass('has-error');
                alert('Kontrollér antallet som er skrevet inn. Kun heltall er lov.')
                return;
            }

            var mobileView = ($(window).width() <= 768 && SUB_DOMAIN == 'isushi');
            // Add product
            addToShoppingCart(input.attr('product_id'), amount, parentWrapper, mobileView);

            //If the class "product_article_hide_picture" is added, the config "hide default pictures" is active
            // -> Add position: absolute to the info_wrapper to place it back again after removing it
            if ($('div#products_wrapper div.product_article_hide_picture'). length > 0) {
                parentWrapper.children('div.product_info_wrapper').css('position', 'absolute');
            }
        }
    });

    // Add 1 product to shopping cart
    $('.add_product_simple').click(function (event) {
        var parentWrapper = $(this).parents('article');
        var specialProduct = $(this).attr('special_product');
        var extraProductAutoPopup = $(this).hasClass('extra-products-popup');
        if ((specialProduct !== undefined && specialProduct !== false && specialProduct != '') || extraProductAutoPopup) {
            $('button.extra-products-btn:first', parentWrapper).click();
        } else {
            // Find the input field element
            var input = $('input', parentWrapper);
            var amount = 1;
            var mobileView = ($(window).width() <= 768 && SUB_DOMAIN == 'isushi');
            // Add product
            addToShoppingCart(input.attr('product_id'), amount, parentWrapper, mobileView);
        }

        return false;
    });

    // Add product from product search
    $(document).on('click', 'button.add_product_from_search', function (event) {
        var parentWrapper = $(this).parents('div.purchase_product_from_search_wrapper');
        var specialProduct = $(this).attr('special_product');
        var extraProductAutoPopup = $(this).hasClass('extra-products-popup');
        if ((specialProduct !== undefined && specialProduct !== false && specialProduct != '') || extraProductAutoPopup) {
            $('button.extra-products-btn:first', parentWrapper).click();
            return false;
        } else {
            // Find the input field element
            var input = $('input', parentWrapper);
            var amount = $.trim(input.val()) * 1;

            // Validate amount
            if (!isInteger(amount)) {
                // Alert
                alert('Ugyldig antall: ' + amount);
                return;
            }
            // Add product
            addToShoppingCart(input.attr('product_id'), amount, -1, false);
        }
    });

    // Add product to shopping cart
    $(document).on('click', 'span.delete_product', function (event) {
        //Post product id
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'remove_product',
                product_id: $(this).attr('product_id')
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // If user is in the checkout page, refresh entire page, no need to fetch with ajax
                if (window.location.pathname === '/shop/checkout') {
                    window.location.reload();
                }
                // Update shopping cart
                refreshShoppingCart();
            } else {
                alert(response.error_message);
            }
            //ajaxLockActive = false;
        }, 'json');
    });

    // Edit product in shopping cart
    $(document).on('click', 'span.glyphicon-edit:not(.extra-products-btn)', function (event) {
        // Get parent product container
        var productContainer = $(this).closest('div.shopping_cart_product_container');
        var sumAndDeleteWrapper = $('p.price', productContainer);
        var editAmountWrapper = $('div.edit_amount_wrapper', productContainer);

        // Hide sum and display edit options
        sumAndDeleteWrapper.hide();
        editAmountWrapper.show();
    });

    //When clicking on the delivery tab in checkout, add delivery fee
    $('button[target="shop_delivery_form_wrapper"]').click(function (event) {
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'add_delivery_product'
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // Success
                // Update shopping cart
                refreshShoppingCart('product_added');
            } else {
                alert(response.error_message);
            }
            //ajaxLockActive = false;
        }, 'json');
    });

    // Empty/unset shopping cart
    $(document).on('click', 'button#clear_shopping_cart',function() {
        if (typeof withoutPrompt === 'undefined') {
            if (!confirm('Vil du tømme handlekurv og starte på nytt?')) {
                return;
            }
        }

        //Post serialized form and save ProductCategory
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'unset_shopping_cart'
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // Refresh page
                window.location.href = "/shop";
            } else {
                alert(response.error_message);
            }
            //ajaxLockActive = false;
        }, 'json');
    });

    /**
     * Updates the number in the amount input when editing amount of product in shopping cart
     *
     * @param String action minus or plus
     * @param Element element the element that was clicked
     * @returns
     */
    function updateInputWithAmountOfProduct(action, element) {
        var amountInput = $('input', element.parent());
        var amount = $.trim(amountInput.val());
        if (isNaN(amount)) {
            amountInput.val('0');
            return;
        }
        if (action === 'minus' && (amount * 1) <= 1) {
            return;
        }

        var newAmount = 0;
        if (action === 'minus') {
            newAmount = (amount * 1) - 1;
        } else {
            newAmount = (amount * 1) + 1;
        }

        if (!isInteger) {
            amountInput.addClass('has-error');
            amountInput.val('0');
            return;
        }
        amountInput.val(newAmount);
    }

    /**
     * Updates the number in the amount input when editing amount of product in shopping cart
     *
     * @param String action minus or plus
     * @param Element element the element that was clicked
     * @returns
     */
    function updateInputWithAmountBeforeAddingToShoppingCart(action, element) {
        var amountInput = $('input', element.parent());
        var amount = $.trim(amountInput.val());
        if (isNaN(amount)) {
            amountInput.val('0');
            return;
        }
        if (action === 'minus' && (amount * 1) <= 1) {
            return;
        }

        var newAmount = 0;
        if (action === 'minus') {
            newAmount = (amount * 1) - 1;
        } else {
            newAmount = (amount * 1) + 1;
        }

        if (!isInteger) {
            amountInput.addClass('has-error');
            amountInput.val('0');
            return;
        }
        amountInput.val(newAmount);
    }

    // Edit amount of a product by using the minus or plus signal (Editing amount in shopping cart)
    $(document).on('click', 'div.edit_amount_wrapper span.glyphicon-minus', function (event) {
        updateInputWithAmountOfProduct('minus', $(this));
    });
    $(document).on('click', 'div.edit_amount_wrapper span.glyphicon-plus', function (event) {
        updateInputWithAmountOfProduct('plus', $(this));
    });

    // Edit amount of a product by using the minus or plus signal (Before adding to shopping cart)
    $(document).on('click', 'div.purchase_product_wrapper span.glyphicon-minus', function (event) {
        updateInputWithAmountBeforeAddingToShoppingCart('minus', $(this));
    });
    $(document).on('click', 'div.purchase_product_wrapper span.glyphicon-plus', function (event) {
        updateInputWithAmountBeforeAddingToShoppingCart('plus', $(this));
    });
    $(document).on('click', 'div.purchase_product_wrapper button.product-minus', function (event) {
        updateInputWithAmountBeforeAddingToShoppingCart('minus', $(this));
    });
    $(document).on('click', 'div.purchase_product_wrapper button.product-plus', function (event) {
        updateInputWithAmountBeforeAddingToShoppingCart('plus', $(this));
    });

    // Update amount of product in shopping cart. 
    $(document).on('click', 'button.edit_product_amount', function () {
        var productWrapper = $(this).closest('div.shopping_cart_product_container');
        var productId = productWrapper.attr('product_id');
        var amountInput = $('input', $(this).parent());
        var amount = $.trim(amountInput.val().replace(',', '.'));
        amountInput.removeClass('has-error');
        if (isNaN(amount)) {
            amountInput.addClass('has-error');
            amountInput.val('0');
            return;
        }
        if (!isInteger) {
            amountInput.addClass('has-error');
            amountInput.val('0');
            return;
        }

        updateShoppingCartProductCount(productId, amount);
    });

    // Search for product
    $('div.shop-products-col-frame button#product_search_button').click(function () {
        search();
    });

    /*
     * Search if enter key in the search field
     */
    $('div.shop-products-col-frame input#product_search_input').keyup(function (e) {
        var key = e.which;
        var searchInput = $('input#product_search_input');
        // Refresh list if enter was pressed or the search field is empty
        if (key === 13) {
            search();
        }

        if (searchInput.val() === '') {
            // Remove products if empty search field
            $('div#product_search_result_wrapper').html('');
        }
    });

    //When clicking on the delivery tab in checkout, add delivery fee
    $('button[target="shop_pick_up_form_wrapper"]').click(function (event) {
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'remove_delivery_product'
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // Success
                // Update shopping cart
                refreshShoppingCart();
            } else {
                alert(response.error_message);
            }
            //ajaxLockActive = false;
        }, 'json');
    });

    // Display button to copy production order when hovering over tr
    jQuery('table#frontline_customer_production_orders tr').hover(function () {
        jQuery(this).find("a.copy_production_order ").removeClass('hidden');
    }, function () {
        jQuery(this).find("a.copy_production_order ").addClass('hidden');
    });

    // Add anchor to menu links if small screen
    addAnchorsToMenuIfSmallScreen();

    // Compensate for header height if anchor is set
    scrollToCompensateForHeader();

    /**
     * Hides product purchase options and displays image and product image
     *
     * @param Element productArticle
     * @return
     */
    function hidePurchaseOptions(productArticle) {

        // Hide image and display purchase options
        $('img', productArticle).css('height', '');

        // Switch plus sign with minus sign
        switchFromMinusToPlus($('span.glyphicon-minus-sign', productArticle));

        // Hide description
        $('p.description span.hidden-normal', productArticle).removeClass('hidden').addClass('hidden');
        $('p.description span.hidden-normal', productArticle).removeClass('hidden').addClass('hidden');

        if (!($('div.shop-products-col-frame div.product_layout_1').length)) {
            // Show direct order button if the product layout 1 is not activated
            $('button.add_product_simple').show();
        }

        productArticle.removeClass('open-purchase-options');
        productArticle.addClass('closed-purchase-options');

        // Display purchase option
        $('div.purchase_product_wrapper', productArticle).hide();

        //If product_layout_1 is activated
        if ($('div.product_layout_1').length) {
            // Show button "Vis mer..."
            $(productArticle).find('button.description-read-more').show();
        }

        //If product_layout_2 is activated -> show image wrapper
        if ($('div.product_layout_2').length) {
            $(productArticle).find('div.product_image_wrapper').show();
        }

        //If product_layout_3 is activated -> show image wrapper
        if ($('div.product_layout_3').length) {
            $(productArticle).find('div.product_image_wrapper').show();
        }
    }

    /**
     * Hides product purchase options and displays image and product image
     *
     * @param Element productArticle
     * @return
     */
    function hidePurchaseOptionsMobile(productArticle) {
        // Hide image and display purchase options
        $('img', productArticle).css('display', 'none');

        // Switch plus sign with arrow sign
        switchFromMinusToArrow($('span.glyphicon-minus-sign', productArticle));

        // Show direct order button
        $('button.add_product_simple').show();

        // Hide description
        $('p.description', productArticle).hide();

        // Hide purchase option
        $('div.purchase_product_wrapper', productArticle).hide();

        $(productArticle).parents('.product_article_mobile').removeClass('open');
    }

    /**
     * Switches from span class from plus symbol to minus symbol
     * Sets font color as red
     *
     * @param  span
     * @return
     */
    function switchFromPlusToMinus(span) {
        // Switch plus sign with minus sign
        span.removeClass('glyphicon-plus-sign');
        span.addClass('glyphicon-minus-sign');
    }

    /**
     * Switches from span class from plus symbol to minus symbol
     * Sets font color as red
     *
     * @param element span
     * @return
     */
    function switchFromMinusToPlus(span) {
        // Switch plus sign with minus sign
        span.addClass('glyphicon-plus-sign');
        span.removeClass('glyphicon-minus-sign');
    }

    /**
     * Switches from span class from plus symbol to minus symbol
     * Sets font color as red
     *
     * @param  span
     * @return
     */
    function switchFromArrowToMinus(span) {
        // Switch plus sign with minus sign
        span.removeClass('glyphicon-chevron-right');
        span.addClass('glyphicon-minus-sign');
    }

    /**
     * Switches from span class from plus symbol to minus symbol
     * Sets font color as red
     *
     * @param element span
     * @return
     */
    function switchFromMinusToArrow(span) {
        // Switch plus sign with minus sign
        span.addClass('glyphicon-chevron-right');
        span.removeClass('glyphicon-minus-sign');
    }

    /**
     * Returns true if n is integer
     *
     * @param {integer} n
     * @returns {Boolean}
     */
    function isInteger(n) {
        return n === +n && n === (n | 0);
    }

    /**
     * Fetches shopping cart html with ajax and replaces current shopping cart.
     *
     * @return
     */
    refreshShoppingCart = function (actionBeforeRefresh) {
        var actionBeforeRefresh = typeof actionBeforeRefresh !== 'undefined' ? actionBeforeRefresh : 'not set';

        // Type is the active shop type page
        // E.g /shop/1: type = 1
        if ($('#breadcrumbs_row #crumbs a.active').length > 0) {
            var type = $('#breadcrumbs_row #crumbs a.active').data('type');
        } else {
            var type = $('nav#menu').attr('type');
        }
        if (window.location.pathname === '/shop/checkout') {
            type = 'checkout';
        } else if (window.location.pathname === '/shop/shoppingcart') {
            type = 'shopping_cart_full';
        }

        // Update shopping cart in mobile view or normal view
        //if ($('div#shopping_cart_header').is(':visible')) {
        // Mobile
        var action = '';
        if (window.location.pathname === '/shop/shoppingcart') {
            // Full view
            action = 'get_shopping_cart_sum_and_full';
        } else {
            // Mobile view with only the shopping cart sum in the header
            action = 'get_shopping_cart_sum';
        }

        //Post serialized form and save ProductCategory
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: action,
                type: type
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // Success
                // Replace html
                if (type === 'shopping_cart_full') {
                    $('div#clear_shopping_cart_full').remove();
                    $('div#shopping_cart_full').replaceWith(response.html);
                }

                $('span#shopping_cart_header_sum').html(response.sum);

                if (response.product_count > 0) {
                    $('span#shopping_cart_product_count').html(response.product_count + 'x');
                }

                if ($('span#shopping_cart_product_count_badge').length) {
                    if (response.product_count > 0) {
                        $('span#shopping_cart_product_count_badge').html(response.product_count);
                        if ($('span#shopping_cart_product_count_badge').hasClass('animate_badge')) {
                            $('span#shopping_cart_product_count_badge').removeClass('animate_badge');
                        }
                        setTimeout(function () {
                            $('span#shopping_cart_product_count_badge').addClass('animate_badge')
                        }, 50);
                    }
                }
            } else {
                alert(response.error_message);
            }
            //ajaxLockActive = false;
        }, 'json');
        if ($('div#shopping_cart_small').is(':visible')) {
            // Normal
            $.ajax({
                type: 'POST',
                url: ajaxPath,
                data: {
                    action: 'get_shopping_cart',
                    type: type
                }
            }).done(function (response) {
                // Validate response
                var success = true;
                if (response.status === undefined) {
                    success = false;
                } else if (response.status.toUpperCase() !== 'OK') {
                    success = false;
                }

                if (success) {
                    // Success
                    // Replace html
                    $('.button-below-shopping-cart').remove();
                    $('div#shopping_cart_small').replaceWith(response.html);

                    if (actionBeforeRefresh === 'product_added') {
                        var lastProduct = $('div#shopping_cart_small div.shopping_cart_product_container:last');
                        if (lastProduct.length) {
                            // Animate last row of shopping cart to the right
                            if (lastProduct.hasClass('animate_last_product_added_to_shopping_cart')) {
                                lastProduct.removeClass('animate_last_product_added_to_shopping_cart');
                            }
                            setTimeout(function () {
                                lastProduct.addClass('animate_last_product_added_to_shopping_cart')
                            }, 50);
                        }
                    }

                    setShoppingCartPlacement();
                } else {
                    alert(response.error_message);
                }
                //ajaxLockActive = false;
            }, 'json');
        }
    }

    if ($('div#shopping_cart_small').length) {
        var navbarElement = $('header.navbar-fixed-top');
        // Keep shopping cart in fixed position
        var originalShoppingCartTopPos = $('div#shopping_cart_small').offset().top;
        // Add header hight to margin-top so the table will appear under the menu
        var headerHeight = navbarElement.css('position') === 'relative' ? 0 : navbarElement.height();

        if ($('div#products_wrapper').length) {
            var productsHeight = $('div#products_wrapper').offset().top;
        }
    }

    /**
     * Adds product in given amount to shopping cart
     *
     * @param int productId
     * @param int amount
     * @param Element parentWrapper (-1 if not adding product from normal user webshop)
     * @param {boolean} mobileView
     * @returns {undefined}
     */
    function addToShoppingCart(productId, amount, parentWrapper, mobileView) {
        //Post serialized form and save ProductCategory
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'add_product',
                product_id: productId,
                amount: amount
            }
        }).success(function (data) {
            let isSmallScreenSize = window.matchMedia("only screen and (max-width: 767px)").matches;

            // Update shopping cart
            refreshShoppingCart('product_added');

            if (isSmallScreenSize) {
                printAddedToShoppingCartBar();
            }


            // Hide purchase options and display product image
            if (parentWrapper !== -1) {
                if (mobileView) {
                    hidePurchaseOptionsMobile(parentWrapper);
                } else {
                    if ($('div.product_layout_4').length < 0) {
                        hidePurchaseOptions(parentWrapper);
                    }
                }

                // Flash "Bestill" button
                if ($('button.add_product_simple', parentWrapper).length) {
                    if ($('button.add_product_simple', parentWrapper).hasClass('animate_add_to_cart_button')) {
                        $('button.add_product_simple', parentWrapper).removeClass('animate_add_to_cart_button');
                    }
                    setTimeout(function () {
                        $('button.add_product_simple', parentWrapper).addClass('animate_add_to_cart_button')
                    }, 50);
                }
            }
        }).error(function (data) {
            $('.modal').modal('hide');
            modalTitle = $.parseJSON(data.responseText).hasOwnProperty('error_title') ? $.parseJSON(data.responseText).error_title : 'Det oppstod en feil';
            modalMessage = $.parseJSON(data.responseText).error_message;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    }

    /**
     * Updates product count to given amount in shopping cart
     *
     * @param int productId
     * @param int amount
     * @returns {undefined}
     */
    function updateShoppingCartProductCount(productId, amount) {
        //Post serialized form and save ProductCategory
        $.ajax({
            type: 'POST',
            url: ajaxPath,
            data: {
                action: 'update_product_amount',
                product_id: productId,
                amount: amount
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // If user is in the checkout page, refresh entire page, no need to fetch with ajax
                if (window.location.pathname === '/shop/checkout') {
                    window.location.reload();
                }

                // Success
                // Update shopping cart
                refreshShoppingCart();
            } else {
                alert(response.error_message);
            }
        }, 'json');
    }

    /**
     * Keeps shopping cart in a fixed position by setting margin top
     *
     * @returns
     */
    function setShoppingCartPlacement() {
        let shoppingCart = $('div#shopping_cart_small');
        if (!shoppingCart.length) {
            // Shopping cart does not exist on page
            return;
        }
        if (shoppingCart) {
            let shoppingCartHeight = shoppingCart.height();
            let shoppingCartWidth = shoppingCart.outerWidth();
            let windowHeight = $(window).height();

            if (shoppingCartHeight > (windowHeight - originalShoppingCartTopPos)) {
                //Should be possible to scroll to bottom of shopping cart when its higher than the window height
                shoppingCart.css('position', 'relative');
                shoppingCart.stop().animate({'margin-top': 0}, 2000);
            } else {
                //Set fixed position on shopping cart to make it stick to one screen position when we scroll
                shoppingCart.css('position', 'fixed');
                shoppingCart.css('width', shoppingCartWidth);
            }
        }
    }

    //Set fixed/relative position on shopping cart on scroll
    $(window).scroll(function () {
        setShoppingCartPlacement();
    });

    /**
     * Adds anchors to all product category menu options if screen is small.
     *
     * @returns
     */
    function addAnchorsToMenuIfSmallScreen() {
        if ($(window).width() >= 768) {
            return;
        }

        $('nav#menu a').each(function () {
            if ($(this).parent().find('ul').length) {
                // Menus with child menu
                var newHref = $(this).attr('href') + "#breadcrumbs_row";
                $(this).attr('href', newHref);
            } else {
                // Menus with no children
                var newHref = $(this).attr('href') + "#products_wrapper";
                $(this).attr('href', newHref);
            }
        });
    }

    /**
     * Since the header is fixed at the top, anchors will not work correctly.
     * Scroll up to compensate for the header height.
     *
     * @returns
     */
    function scrollToCompensateForHeader() {
        var url = window.location.hash;
        var index = url.indexOf("#");
        if (index != -1) {
            var header = $('header.navbar');
            var y = $(window).scrollTop();  //your current y position on the page
            $(window).scrollTop(y);
        }
    }

    function search() {
        var searchText = $.trim($('input#product_search_input').val());
        // Do nothing if empty search word
        if (searchText === '') {
            return;
        }

        //Post search text
        $.ajax({
            type: 'POST',
            url: '/includes/shop/ajax/ajax.php',
            data: {
                action: 'product_search',
                search_text: searchText
            }
        }).done(function (response) {
            // Validate response
            var success = true;
            if (response.status === undefined) {
                success = false;
            } else if (response.status.toUpperCase() !== 'OK') {
                success = false;
            }

            if (success) {
                // Success

                // Check if product was added automatically to shopping cart (only one product matchced searh
                if (response.refresh_shopping_cart !== undefined && response.refresh_shopping_cart === '1') {
                    // Display success message
                    $('div#product_search_result_wrapper').html(response.info_message);

                    // refresh shopping cart
                    refreshShoppingCart('product_added');

                    // Remove text in search field
                    $('input#product_search_input').val('');
                } else {
                    // Update search result table
                    $('div#product_search_result_wrapper').html(response.html);
                }

            } else {
                alert(response.error_message);
            }
            //ajaxLockActive = false;
        }, 'json');
    }

    $(document).on('click', 'div#select_consuming_location_wrapper button#select_eat_in_button, div#select_consuming_location_wrapper button#select_take_away_button', function () {
        let consumingLocation = $(this).val();
        let shoppingCartSmall = $('div#shopping_cart_small div.shopping_cart_product_container');
        let products = getProductsFromShoppingCart(shoppingCartSmall);

        if(products.length > 0) {
            if (!confirm('Dersom du endrer denne nå, kan det påvirke varene du har lagt i handlekurven.')) {
                return;
            }
        }

        $.ajax({
            type: 'POST',
            url: ajaxPathSetConsumingLocation,
            data: {
                consuming_location: consumingLocation
            },
            dataType: 'json'
        }).success(function (data) {
            location.reload();
        }).error(function (data) {
            modalTitle = 'Det oppstod en feil!';
            modalMessage = $.parseJSON(data.responseText).errors;
            modalAlertType = 'error';

            displayInfoMessageModal(modalParentElementInDOM, modalTitle, modalMessage, modalButtonText, modalAlertType);
        });
    });

    function addReadMoreLinkOnProductDescriptions() {
        $('p.description').each(function() {
            let descriptionElement = $(this);
            let productId = descriptionElement.attr('product_id');
            let descriptionText = descriptionElement.find('.hidden-normal').text();

            if (descriptionText.length) {
                let visibleDescriptionHeight = descriptionElement.innerHeight();

                if (descriptionElement.prop('scrollHeight') > visibleDescriptionHeight) {
                    //this element is overflowing on the y axis
                    let readMoreLink = '<button product_id="' + productId + '" class="btn-link extra-products-btn description-read-more">Vis mer...</button>';
                    descriptionElement.after(readMoreLink);
                    descriptionElement.css('margin-bottom', 0);
                }
            }
        });
    }
});